import styled from 'styled-components';

export const Wapper = styled.div`
    position: fixed;
    bottom: 30px;
    left: 10px;
`

export const Button = styled.button`
    border: none;
    padding: 5px 8px;
    border-radius: 100%;
    color: #000;
    font-size: 20px;
    background-color: rgba(3,3,3,0.2);

    :hover{
        background-color: #00cc00;
        color: #fff;
        transform: scale(1.2);
    }
`
