import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { Wapper, Button } from "./styles";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
    const navigate = useNavigate();
    return <Wapper>
        <Button onClick={() => navigate(-1)}><FontAwesomeIcon icon={faReply} /></Button>
    </Wapper >;
};


export default BackButton;
