import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, MLabel } from './styles';
import { Link } from 'react-router-dom';

const InquiryItem = ({ objInquiry }) => {
    return (
        <Wrapper>
            <Link to={"#"}>
                <span className='title'>{objInquiry.InquiryName}</span>
                <div>
                    <span ><MLabel>区分：</MLabel>{objInquiry.InquiryTypeName || '－'}</span>
                    <span className='ml-30'><MLabel>日時：</MLabel>{objInquiry.InquiryDate ? objInquiry.InquiryDate.slice(0, 10) : '－'}</span>
                </div>
                <span ><MLabel>担当者：</MLabel>{objInquiry.StaffName || '－'}</span>
                <span ><MLabel>ステータス：</MLabel>{objInquiry.StatusName || '－'}</span>
                <span ><MLabel>内容：</MLabel>{objInquiry.InquiryContent || '－'}</span>
            </Link>
        </Wrapper>
    )
}

InquiryItem.propTypes = {
    objInquiry: PropTypes.object.isRequired,
}

export default InquiryItem;
