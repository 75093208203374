import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CheckBox, Menu, OverLay, MenuClose, HeaderMenu, UserInfo, BottomMenu, BodyMenu, ContainerInfo, SubUserInfo } from './styles';
import { NavLink, useNavigate } from 'react-router-dom';
import { LocalStorageGetStaff, LocalStorageRemoveToken } from 'utils';
import loginApi from 'api/loginApi';



const NavMenu = ({ idMenu, userName }) => {
    const staff = LocalStorageGetStaff();
    const navigate = useNavigate();

    const handleLogoutClick = async () => {
        try {
            const dtResponse = await loginApi.logout();
            console.log(dtResponse);
            LocalStorageRemoveToken();
            navigate("/login");
        } catch (error) {
            console.log(error);

        }
    }

    return (
        <>
            <CheckBox id={idMenu} hidden />
            <OverLay htmlFor={idMenu} />
            <Menu>
                {/* <Wrapper> */}
                <HeaderMenu>
                    <ContainerInfo>
                        <UserInfo>{staff?.staffName || '-'}</UserInfo>
                    </ContainerInfo>
                    <MenuClose htmlFor={idMenu} className='button icon'>
                        <FontAwesomeIcon icon={faTimes} />
                    </MenuClose>
                </HeaderMenu>
                <BodyMenu>
                    <li>
                        <NavLink to={'/occation'} className='menu-item' >案件検索</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/new_inquiry'} className='menu-item' >問合せ登録</NavLink>
                    </li>

                </BodyMenu>
                <BottomMenu onClick={handleLogoutClick}>
                    ログアウト <FontAwesomeIcon icon={faRightFromBracket} />
                </BottomMenu>
                {/* </Wrapper> */}
            </Menu>
        </>
    )
}

NavMenu.propTypes = {
    idMenu: PropTypes.string,
    userName: PropTypes.string
}

NavMenu.defaultProps = {
    idMenu: 'id',
    userName: 'スタッフ名前',
}
export default NavMenu;
