import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    background-color: #eee;
    border-bottom: 1px solid #ccc; 
    @media screen and (max-width: 768px){
  
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;   
        padding: 8px 15px;           
        cursor: pointer;
        :active {
            color: #fff;
            background-color: var(--morienGreen);
        }
    }

    .borderLeft{         
        border-left: 1px solid #ccc; 
    }

    .borderRight{ 
        border-right: 1px solid #ccc;
    }

    .icon{
        font-size: 1.5rem;
    }
`;

export const Label = styled.label`
   
`;


export const HeaderTitle = styled.div`
    flex-grow: 1;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
`;

export const Cart = styled.label`
    position: relative;
`;

export const GoodsNum = styled.div`
    color: white;
    border-radius: 50%;
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    top: 1px;
    right: 3px;
    margin: 0;
    padding: 0 6px;
    background-color: var(--morienGreen);

`;


