import { Wrapper, Content, OccationInfo, MLabel } from './styles'
import { useOccationDetails } from "./useOccationDetails";
import Loading from "components/Loading";
import HeaderBox from "components/HeaderBox";
import InquiryList from "../components/InquiryList";
import BackButton from 'components/BackButton';

const OccationDetails = () => {

    const { objOccation, inquiryList, isLoading } = useOccationDetails();
    return (
        <>
            {!objOccation ? null :
                <Wrapper>
                    <Loading isLoading={isLoading} />
                    <HeaderBox title={objOccation.OccationName} />
                    <Content>
                        <OccationInfo>
                            <div>
                                <MLabel>住所：</MLabel>
                                〒{objOccation.PostalCode || '―'}　{objOccation.Address || '―'}
                            </div>
                            <div>
                                <MLabel>担当者：</MLabel>{objOccation.StaffName || '－'}
                            </div>
                            <div>
                                <MLabel>エリア：</MLabel>{objOccation.BaseName || '－'}
                            </div>
                            <div>
                                <MLabel>ステータス：</MLabel>{objOccation.StatusName || '－'}
                            </div>
                            <div>
                                <MLabel>次回アポ日：</MLabel>{objOccation.NextAppoinment ? objOccation.NextAppoinment.slice(0, 10) : '－'}
                            </div>
                            <div>
                                <MLabel>お客様：</MLabel>{objOccation.ClientName || '－'}
                            </div>
                            <div style={{ display: "inline-block" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}>
                                <MLabel>TEL: </MLabel><a href={`tel:${objOccation.Tel}`}>{objOccation.Tel || "-"}</a>
                            </div>
                            <div>
                                <MLabel>現場：</MLabel>{objOccation.SiteName || ''}
                            </div>
                            <div>
                                <MLabel>見込み：</MLabel><span>{objOccation.Prospect || '－'}</span>
                            </div>
                            <div>
                                <MLabel>相見積もり：</MLabel><span>{objOccation.PhaseEstimate || '－'}</span>
                            </div>
                            <div>
                                <MLabel>予算帯：</MLabel><span>{objOccation.BudgetZone || '－'}</span>
                            </div>
                            <div>
                                <MLabel>予算：</MLabel><span>{objOccation.Budget || '－'}</span>
                            </div>
                            <div>
                                <MLabel>キーマン：</MLabel><span>{objOccation.KeyManMemo || '－'}</span>
                            </div>
                            <div>
                                <MLabel>失注要因：</MLabel><span>{objOccation.LossFactor || '－'}</span>
                            </div>
                            <div>
                                <MLabel>備考：</MLabel><span>{objOccation.OccationMemo || '－'}</span>
                            </div>
                            <div>
                                <MLabel>認知経路：</MLabel><span>{`${objOccation.OccationLearnedFromName}－${objOccation.OccationLearnedFromMemo}`}</span>
                            </div>
                            <div>
                                <MLabel>築年数：</MLabel><span>{objOccation.ConstructionAge || '－'}</span>
                            </div>
                            <div>
                                <MLabel>年齢：</MLabel><span>{objOccation.AgeZone || '－'}</span>
                            </div>
                            <div>
                                <MLabel>外壁素材：</MLabel><span>{objOccation.MaterialOfWall || '－'}</span>
                            </div>
                            <div>
                                <MLabel>屋根素材：</MLabel><span>{objOccation.MaterialOfRoof || '－'}</span>
                            </div>
                            <div>
                                <MLabel>７つの阻害要因：</MLabel><span>{objOccation.Obstacle || '－'}</span>
                            </div>
                            <div>
                                <MLabel>メモ：</MLabel><span>{objOccation.ConstructionMemo || '－'}</span>
                            </div>
                            <InquiryList occationID={objOccation.OccationID} occationName={objOccation.OccationName} inquiryList={inquiryList} />
                        </OccationInfo>
                    </Content>
                    <BackButton />
                </Wrapper>
            }
        </>
    );
};

export default OccationDetails;
