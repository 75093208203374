import { useEffect, useState } from "react";
import occationApi from "api/occationApi";
import { useParams } from "react-router-dom";


export const useOccationDetails = () => {
    const { id: occationID } = useParams();
    const [objOccation, setObjOccation] = useState(null);
    const [inquiryList, setInquiryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async function getOccationInfo() {
            try {
                setIsLoading(true);
                if (!occationID) {
                    setObjOccation(null);
                    return;
                };
                const params = {
                    occationID: occationID
                }
                const dtResponse = await occationApi.getOccationInfo(params);
                const occationInfo = dtResponse?.data?.occationInfo;
                const dtInquiry = dtResponse?.data?.dtInquiry;
                if (occationInfo) {
                    setObjOccation(occationInfo);
                    setInquiryList(dtInquiry);
                } else {
                    setObjOccation(null);
                }
            } catch (error) {
                console.error(error);

            } finally {
                setIsLoading(false);
            }
        })();
        return;
    }, [occationID])

    return { objOccation, inquiryList, isLoading }
}