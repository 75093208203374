import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Content = styled.div`
    /* width:100%; */
    flex-grow:1;
    display:flex;
    flex-direction: column;
    overflow-y: scroll;
    .mx-17{
        margin-left: 17px;
        margin-right: 17px;
    }
    .px-17{
        padding-left: 17px;
        padding-right: 17px;
    }
    input {
        font-size: var(--fontNormal);
        color: var(--black);
        border: 1px solid #808080;
        border-radius: 3px;
    }

    select {
        font-size: var(--fontNormal);
        color: var(--black);
        border: 1px solid #808080;
        border-radius: 3px;
        padding: 0 2px;
    }
    textarea {
        font-size: var(--fontNormal);
        color: var(--black);
        border: 1px solid #808080;
        border-radius: 3px;
        padding: 4px;
    }
    .w-100{
        width: 100%
    }
    .btn-control {
        display: flex;
        justify-content: flex-end;
    }
`;

export const MButton = styled.button`
    margin-top: 5px;
    padding:5px 20px;
    background-color: var(--morienGreen);
    border: 1px solid var(--morienGreen);
    border-radius: 3px;
    color: #fff;
    text-decoration: none;
    font-size:var(--fontNormal);
    font-weight:400;    
`

export const MLabel = styled.label`
    font-size: var(--fontSmall);
    padding: 0 5px;
    color: #6c757d;
`

export const Title = styled.span`
    color:var(--morienGreen);
    font-weight:bold;
`
export const Line = styled.div`
`