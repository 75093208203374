import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { Wrapper, Content, MLabel, MButton, Title, Line } from './styles'
import { useInquiryAddNew } from "./useInquiryAddNew";
import Loading from "components/Loading";
import HeaderBox from "components/HeaderBox";
import MessageBox from "components/MessageBox";
import InputSelectBox from "components/InputSelectBox";
import { useOccationBox } from "hooks/useClientBox";
import { MESSAGE } from "constants";
import { useNavigate } from 'react-router-dom';

const dNow = new Date();
const strNow = `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`;
const renderOccationItem = (item) => {
    return <Wrapper >
        <Content>
            <Title>{item.OccationName}</Title>
            <Line>
                〒{item.PostalCode || '－'}　{item.Address || '－'}
            </Line>


            <Line>
                <div style={{ display: "inline-block" }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}>
                    <MLabel>TEL: </MLabel><a href={`tel:${item.Tel}`}>{item.Tel || "-"}</a>
                </div>
            </Line>
            <Line>
                <MLabel>担当者：</MLabel>{item.StaffName || ''}
            </Line>
            <Line>
                <MLabel>ステータス：</MLabel>{item.StatusName || ''}
            </Line>
        </Content>
    </Wrapper>
}
const InquiryAddNew = () => {
    const { setObjInquiry, inquiryTypeList, inquiryStatusList, isLoading, setIsLoading, message, setMessage } = useInquiryAddNew();
    const { occationList, termOccation, setTermOccation } = useOccationBox({ setIsLoading: setIsLoading, setMessage: setMessage });
    const location = useLocation();
    const [occation, setOccation] = useState({ id: location.state?.occationID || 1, name: location.state?.occationName || "未入力" });
    const [inquiryDate, setInquiryDate] = useState(strNow);
    const [inquiryTypeID, setInquiryTypeID] = useState(0);
    const [inquiryStatusID, setInquiryStatusID] = useState(0);
    const [inquiryContent, setInquiryContent] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        setInquiryTypeID(inquiryTypeList.length > 0 ? inquiryTypeList[0].InquiryTypeID : 0);
    }, [inquiryTypeList])
    useEffect(() => {
        setInquiryStatusID(inquiryStatusList.length > 0 ? inquiryStatusList[0].StatusID : 0);
    }, [inquiryStatusList])

    function handleSave_Click() {
        //validate
        let msg = "";
        if (occation.id <= 1) {
            msg = "案件を選択してください";
        } else if (!inquiryTypeID) {
            msg = "区分を選択してください";
        } else if (!inquiryStatusID) {
            msg = "ステータスを選択してください";
        }

        if (msg) {
            setMessage({
                type: MESSAGE.ERROR,
                contents: [msg]
            })
            return;
        }
        const obj = {
            occationID: occation.id,
            inquiryDate,
            inquiryTypeID,
            inquiryStatusID,
            inquiryContent
        };
        setObjInquiry({ ...obj });


    }
    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title='問合せ登録' />
            <MessageBox message={message} setMessage={setMessage} />
            <Content>
                <div >
                    <MLabel>案件名：</MLabel>
                    <InputSelectBox
                        title='案件を選択してください'
                        name={occation.name}
                        textHolder={'案件名'}
                        termSearch={termOccation}
                        setTermSearch={setTermOccation}
                        resultList={occationList}
                        renderItem={renderOccationItem}
                        selectItem={(occ) => {
                            setOccation({ id: occ.OccationID, name: occ.OccationName })
                        }}
                        classNameCss={'px-17'}
                    />
                </div>
                <div>
                    <MLabel>日付：</MLabel>
                    <div className='mx-17'>
                        <input type='date'
                            style={{ padding: "2px 8px" }}
                            min='2010-01-01'
                            max='2100-12-31'
                            value={inquiryDate}
                            onChange={(e) => { setInquiryDate(e.target.value) }}
                        />
                    </div>
                </div>
                {inquiryTypeList.length > 0 ? (
                    <div>
                        <MLabel>区分：</MLabel>
                        <div className='mx-17'>
                            <select value={inquiryTypeID}
                                onChange={(e) => { setInquiryTypeID(e.target.value) }}>
                                {inquiryTypeList.map((iType, index) => {
                                    return (<option key={iType.InquiryTypeID} value={iType.InquiryTypeID}>{iType.InquiryTypeName}</option>);
                                })}
                            </select>
                        </div>
                    </div>
                ) : null}
                {inquiryStatusList.length > 0 ? (
                    <div>
                        {/* Editable */}
                        <MLabel>ステータス：</MLabel>
                        <div className='mx-17'>
                            <select value={inquiryStatusID}
                                onChange={(e) => { setInquiryStatusID(e.target.value) }}>
                                {inquiryStatusList.map((iStatus) => {
                                    return (<option key={iStatus.StatusID} value={iStatus.StatusID}>{iStatus.StatusName}</option>)
                                })}
                            </select>
                        </div>
                    </div>
                ) : null}
                <div>
                    <MLabel>内容：</MLabel>
                    <div className='mx-17'>
                        <textarea
                            type='text'
                            maxLength={500}
                            style={{ width: '100%', minHeight: '170px' }}
                            value={inquiryContent}
                            onChange={(e) => { setInquiryContent(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="mx-17" style={{ display: "flex", justifyContent: "space-between" }}>
                    <MButton onClick={() => { navigate(-1); }} style={{ backgroundColor: "#a9a9a9", borderColor: "#a9a9a9" }}>キャンセル</MButton>
                    <MButton onClick={handleSave_Click}>保存</MButton>
                </div>
            </Content>
        </Wrapper>
    );
};

export default InquiryAddNew;
