import { TOKEN_KEY } from "constants";

export const LocalStorageSetToken = (xUserID, xToken, staffID, staffName) => {
    const userInfo = {
        xUserID: xUserID,
        xToken: xToken,
        staffID: staffID,
        staffName: staffName,
    }
    window.localStorage.setItem(TOKEN_KEY, JSON.stringify(userInfo));
};

export const LocalStorageGetToken = () => {
    const userInfo = JSON.parse(window.localStorage.getItem(TOKEN_KEY)) || {};
    return { xUserID: userInfo.xUserID || '', xToken: userInfo.xToken || '' };
};

export const LocalStorageRemoveToken = () => {
    window.localStorage.removeItem(TOKEN_KEY);
};


// Staff
export const LocalStorageGetStaff = () => {
    const userInfo = JSON.parse(window.localStorage.getItem(TOKEN_KEY)) || {};
    return { staffID: userInfo.staffID || '', staffName: userInfo.staffName || '' };
};


export const LocalStorageClearAll = () => {
    LocalStorageRemoveToken();
}