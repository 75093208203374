import axiosClient from "./axiosClient";

const occationApi = {
    search(params) {
        const url = '/occation';
        return axiosClient.get(url, { params: params });
    },
    getOccationInfo(params) {
        const url = '/occation/info';
        return axiosClient.get(url, { params: params });
    },
}

export default occationApi;
