import { useEffect, useRef, useState } from 'react';
import { MESSAGE } from 'constants';
import occationApi from 'api/occationApi';


export const useOccationBox = ({ setIsLoading, setMessage }) => {

    const [termOccation, setTermOccation] = useState({ txtSearch: '' });
    const [occationList, setOccationList] = useState([]);
    const didMounted = useRef(false);

    useEffect(() => {
        async function searchClient() {
            try {
                setIsLoading(true);

                /**Call Api */
                const params = {
                    txtSearch: termOccation?.txtSearch || '',
                }

                const dtResponse = await occationApi.search(params);
                const dtOccation = dtResponse?.data['dtOccation'] || [];
                setOccationList(dtOccation);
                setIsLoading(false);

            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            }
        }

        if (didMounted.current) {
            searchClient();
        }
        didMounted.current = true;
    }, [termOccation, setIsLoading, setMessage])

    return { occationList, termOccation, setTermOccation };
}