import React from 'react';
import PropTypes from 'prop-types';
import { Content, Line, Title, MLabel, Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';

const OccationItem = ({ item }) => {
    const navigate = useNavigate();
    const handlClick = () => {
        navigate(`/occation/${item.OccationID}`);
    }
    return (
        <Wrapper onClick={handlClick}>
            <Content>
                <Title>{item.OccationName}</Title>
                <Line>
                    〒{item.PostalCode || '－'}　{item.Address || '－'}
                </Line>


                <Line>
                    <div style={{ display: "inline-block" }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}>
                        <MLabel>TEL: </MLabel><a href={`tel:${item.Tel}`}>{item.Tel || "-"}</a>
                    </div>
                </Line>
                <Line>
                    <MLabel>担当者：</MLabel>{item.StaffName || ''}
                </Line>
                <Line>
                    <MLabel>ステータス：</MLabel>{item.StatusName || ''}
                </Line>
            </Content>
        </Wrapper>
    )
}

OccationItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default OccationItem;
