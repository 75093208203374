import axiosClient from "./axiosClient";

const staffApi = {
  getStaffList() {
    const url = '/staff';
    return axiosClient.get(url);
  },
}

export default staffApi;
