import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

export const Content = styled.form`
    width: 40%;
    max-width: 400px;
    height: 350px;
    display: flex;
    flex-direction: column;
    background-color: var(--lightGrey);
    padding: 40px 30px;
    margin: 40px 0;
    border-radius: 8px;

    input {
        font-size: 1rem;
        margin-bottom: 10px;
        padding: 5px;
    }

    button {
        margin-top: 5px;
        padding: 5px;
        font-size: 1rem;
        color: white;
        background-color: #007bff;
        border: 1px solid transparent;
        border-color: #007bff;
        border-radius: 5px;
        -webkit-appearance: button;

        :hover {
        background-color: #006de1;
        cursor: pointer;
        }
    }

    @media screen and (max-width: 768px) {
        width: 90%;
        max-width: 400px;
        height: 280px;
        padding: 30px 30px;
        margin: 20px 0;
    }
`;

export const Logo = styled.img`
    width: 100%;
    margin: 16px 0 ;
`;
