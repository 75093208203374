import React, { createRef, useEffect } from 'react';
import HeaderBox from 'components/HeaderBox';
import Loading from 'components/Loading';
import MessageBox from 'components/MessageBox';
import SearchBox from 'components/SearchBox';
import OccationItem from './components/OccationItem';
import { Content, DataEmpty, Wrapper } from './styles';
import { useOccation } from './useOccation';
import { TermOccation } from 'components/TermSearch';

const Occation = () => {
    const { occationList, staffList, termSearch, setTermSearch, isLoading, message, setMessage } = useOccation();

    const refTop = createRef();
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [occationList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title='案件検索' />
            <MessageBox message={message} setMessage={setMessage} />
            <SearchBox placeholder='案件名' termSearch={termSearch} setTermSearch={setTermSearch} >
                <TermOccation setTermSearch={setTermSearch} staffList={staffList} />
            </SearchBox>
            <Content Content ref={refTop} >
                {occationList?.length > 0 ? (
                    occationList.map((item) => {
                        return (<OccationItem key={item.OccationID} item={item} />)
                    })
                ) : (<DataEmpty>データなし</DataEmpty>)
                }
            </Content >
        </Wrapper >
    )
}

export default Occation