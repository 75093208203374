import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { BoxWraper, BtnClear, Input } from './styles';

export const TextBox = ({ id, placeholder, value, enterKeyHint, maxLength, onChange, onClear }) => {
  return <BoxWraper>
    <Input
      id={id}
      type='text'
      placeholder={placeholder}
      value={value}
      enterKeyHint={enterKeyHint}
      autoComplete="on"
      maxLength={maxLength}
      onChange={onChange}
    />
    {!!value && (
      <BtnClear onClick={onClear}><FontAwesomeIcon icon={faCircleXmark} /></BtnClear>
    )}
  </BoxWraper>
}