import { LocalStorageClearAll, LocalStorageGetToken } from "./utils";

const Auth = {
    isLogin: () => {
        const { xUserID, xToken } = LocalStorageGetToken();
        if (!xUserID || !xToken) {
            LocalStorageClearAll();
            return false;
        }
        return true;
    }
}

export default Auth;
