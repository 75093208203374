import React from 'react';
import PropTypes from 'prop-types';
import { HeaderTitle, Label, Wrapper } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import NavMenu from '../NavMenu';

const HeaderBox = ({ title, showCart }) => {
    return (
        <Wrapper>
            <NavMenu idMenu="id_menu" />
            <Label htmlFor='id_menu' className='button icon borderRight ' >
                <FontAwesomeIcon icon={faBars} />
            </Label>
            <HeaderTitle>{title}</HeaderTitle>
        </Wrapper>
    )
}

HeaderBox.propTypes = {
    title: PropTypes.string,
    showCart: PropTypes.bool,
}

HeaderBox.defaultProps = {
    title: '',
    showCart: false,
}

export default HeaderBox
