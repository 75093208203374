import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import occationApi from 'api/occationApi';
import { MESSAGE } from 'constants';
import staffApi from 'api/staffApi';


export const useOccation = () => {

    const location = useLocation();
    const objSearch = useMemo(() => {
        const search = new URLSearchParams(location.search);
        const obj = {
            txtSearch: search.get("search") || '',
        };
        return obj;
    }, [location.search]);

    const [termSearch, setTermSearch] = useState({ txtSearch: objSearch?.txtSearch || '' });

    const [occationList, setOccationList] = useState([]);
    const [staffList, setStaffList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });

    const navigate = useNavigate();
    useEffect(() => {
        (async function getStaffList() {
            try {
                setIsLoading(true);

                /**Call Api */

                const dtResponse = await staffApi.getStaffList();
                const dtStaff = dtResponse?.data['dtStaff'];
                setStaffList([{ StaffID: 0, StaffName: "全て" }, ...dtStaff]);
            } catch (err) {
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);
    useEffect(() => {
        navigate(`/occation?search=${termSearch.txtSearch}`, { replace: true });
    }, [navigate, termSearch])


    useEffect(() => {
        (async function searchOccation() {
            try {
                setIsLoading(true);

                /**Call Api */
                const params = {
                    txtSearch: termSearch?.txtSearch || '',
                    staffID: termSearch?.staffID || 0,
                }
                const dtResponse = await occationApi.search(params);
                const dtOccation = dtResponse?.data['dtOccation'];
                setOccationList(dtOccation);
                setIsLoading(false);

            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            }
        })();
    }, [termSearch])

    return { occationList, staffList, termSearch, setTermSearch, isLoading, message, setMessage };
}