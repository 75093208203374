import React from 'react';
import PropTypes from 'prop-types';
import { Header, DataList, Wrapper } from './styles';
import InquiryItem from '../InquiryItem';
import { Link } from 'react-router-dom';


const InquiryList = ({ occationID, occationName, inquiryList }) => {
    return (
        <Wrapper>
            <Header>
                <div>問合せ</div>
                <Link to="/new_inquiry"
                    state={{
                        occationID: occationID,
                        occationName: occationName,
                    }}
                    className='btn'>登録</Link>
            </Header>
            {
                inquiryList.length < 1 ? <h5 style={{ textAlign: 'center' }}>データなし</h5> :
                    <DataList>
                        {inquiryList.map((objInquiry) => {
                            return (<InquiryItem key={objInquiry.InquiryID} objInquiry={objInquiry} />)
                        })}
                    </DataList>
            }
        </Wrapper >
    )
}

InquiryList.propTypes = {
    occationID: PropTypes.string.isRequired,
    inquiryList: PropTypes.array,
}

InquiryList.defaultProps = {
    inquiryList: []
}
export default InquiryList;
