export const TOKEN_KEY = 'pw-user';
export const BASE_NAME = '';
export const URL_HOME = '/occation';
export const URL_LOGIN = '/login';
export const API_BASE_URL = 'https://pw.morien-paint.com/api';
//export const API_BASE_URL = 'http://pw.localhost:8082/api';

export const MESSAGE = {
    INFO: 'info',
    ERROR: 'error',
    WARNING: 'warning',
}