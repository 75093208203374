import { useEffect, useState } from "react";
import inquiryApi from "api/inquiryApi";
import { LocalStorageGetStaff } from "utils";
import { MESSAGE } from "constants";
import { useNavigate } from "react-router-dom";

const { staffID, staffName } = LocalStorageGetStaff();

export const useInquiryAddNew = () => {

    const navigate = useNavigate();
    const [objInquiry, setObjInquiry] = useState({});
    const [inquiryTypeList, setInquiryTypeList] = useState([]);
    const [inquiryStatusList, setInquiryStatusList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });

    useEffect(() => {
        (async function getInquiryTypeAndStatus(inquiryID) {
            try {
                setIsLoading(true);
                const dtStatus = await inquiryApi.getInquiryStatus(null);
                const statusList = dtStatus?.data?.inquiryStatus;
                if (statusList) setInquiryStatusList(statusList);
                const dtTypeList = await inquiryApi.getInquiryType(null);
                const typeList = dtTypeList?.data?.inquiryType;
                if (typeList) setInquiryTypeList(typeList);
            } catch (error) {
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [error]
                }
                setMessage(errMsg);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);


    useEffect(() => {
        if (!objInquiry?.occationID) return;
        (async function addNewInquiry() {
            try {
                setIsLoading(true);

                const params = {
                    occationID: objInquiry.occationID,
                    inquiryDate: objInquiry.inquiryDate,
                    staffID: staffID,
                    inquiryTypeID: objInquiry.inquiryTypeID,
                    inquiryStatusID: objInquiry.inquiryStatusID,
                    inquiryContent: objInquiry.inquiryContent,
                }
                console.log(params);

                const dtRes = await inquiryApi.addInquiry(params);
                const message = dtRes?.message;
                if (dtRes.status === 200) {
                    setMessage({
                        type: MESSAGE.INFO,
                        contents: [message]
                    });
                    navigate(-1);
                } else { throw Error(message) }

            } catch (error) {
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [error]
                }
                setMessage(errMsg);
                setIsLoading(false);
            }
        })();

    }, [objInquiry, navigate]);

    return { setObjInquiry, inquiryTypeList, inquiryStatusList, isLoading, setIsLoading, message, setMessage }
}