import React from 'react'
import PropTypes from 'prop-types'
import { Button, Content, RightControl, Wrapper } from './styles'

const ItemSelect = ({ children, handleSelect }) => {
    return (
        <Wrapper>
            <Content>
                {children}
            </Content>
            <RightControl>
                <Button onClick={() => { handleSelect() }}>選択</Button>
            </RightControl>
        </Wrapper>
    )
}

ItemSelect.propTypes = {
    handleSelect: PropTypes.func.isRequired
}

export default ItemSelect