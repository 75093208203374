import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PrivateRoute, RestrictedRoute } from './Router';
import { Wrapper } from './App.styles';
import Login from './pages/Login';
import { BASE_NAME } from './constants';
import Occation from 'pages/Occation';
import OccationDetails from 'pages/Occation/Details';
import InquiryAddNew from 'pages/Inquiry/AddNew';



function App() {
    return (
        <Wrapper>
            <BrowserRouter basename={BASE_NAME}>
                <Routes>
                    <Route element={<RestrictedRoute />}>
                        <Route path='/login' element={<Login />} />
                    </Route>
                    <Route element={<PrivateRoute />}>
                        {/* 案件検索 */}
                        <Route path='/*' element={<Occation />} />
                        <Route path='/occation' element={<Occation />} />
                        <Route path='/occation/:id' element={<OccationDetails />} />
                        <Route path='/new_inquiry' element={<InquiryAddNew />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </Wrapper>
    );
}

export default App;