import axiosClient from "./axiosClient";

const inquiryApi = {
    searchInquiry(params) {
        const url = '/inquiry';
        return axiosClient.get(url, { params: params });
    },
    searchInquiryInfo(params) {
        const url = '/inquiry/info';
        return axiosClient.get(url, { params: params });
    },
    getInquiryStatus(params) {
        const url = '/inquiry/status';
        return axiosClient.get(url, { params: params });
    },
    getInquiryType(params) {
        const url = '/inquiry/type';
        return axiosClient.get(url, { params: params });
    },
    updateInquiry(params) {
        const url = '/inquiry/update';
        return axiosClient.post(url, params);
    }
    ,
    addInquiry(params) {
        const url = '/inquiry/add_new';
        return axiosClient.post(url, params);
    }
}

export default inquiryApi
