import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Auth from './Auth';
import { LocalStorageClearAll } from './utils';

export const PrivateRoute = () => {
    const isLogin = Auth.isLogin();
    if (!isLogin) {
        LocalStorageClearAll();
    }
    return isLogin ? <Outlet /> : <Navigate to='/login' />;
}

export const RestrictedRoute = () => {
    const isLogin = Auth.isLogin();

    return isLogin ? <Navigate to='/' /> : <Outlet />;
}