import React, { useState } from "react";
import { TermBoxWrapper, TermLine, TermSelect, TermOption, MLabel } from './styles';

export const TermOccation = ({ setTermSearch, staffList }) => {
  const [staffID, setStaffID] = useState(0);

  return <TermBoxWrapper>
    <TermLine className="flex-wrap">
      <MLabel>担当者：</MLabel>
      <TermSelect className="title-text" value={staffID} onChange={(e) => {
        const num = Number(e.target.value || 1);
        setStaffID(num);
        setTermSearch((preValue) => ({ ...preValue, staffID: num }))
      }}>
        {staffList.map((item) => {
          return (<TermOption key={item.StaffID} value={item.StaffID}>{item.StaffName}</TermOption>);
        })}
      </TermSelect>
    </TermLine>
  </TermBoxWrapper>;
};
