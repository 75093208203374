import PropTypes from 'prop-types';
import React from 'react';
import { Wrapper, Container, Header, Body, Footer, Title, Button } from './styles';
import { MESSAGE } from 'constants';

const MessageBox = ({ message, setMessage }) => {

    function closeBox() {
        setMessage({});
        if (message?.handleClose) message.handleClose();
    }

    return (
        <>
            {message?.contents !== undefined && message?.contents.length > 0 ? (
                <Wrapper>
                    <Container>
                        <Header msgType={message.type}>
                            <Title>{message.type === MESSAGE.ERROR ? 'エラー' : (message.type === MESSAGE.INFO ? '情報' : '警告')}</Title>
                        </Header>
                        <Body msgType={message.type}>
                            {message.contents.map((msg, index) => {
                                return (
                                    <p key={index}>{msg}</p>
                                )
                            })}
                        </Body>
                        <Footer>
                            <Button onClick={() => { closeBox(); }}>閉じる</Button>
                        </Footer>
                    </Container>
                </Wrapper>
            ) : <></>}
        </>
    )
}


MessageBox.propTypes = {
    message: PropTypes.object.isRequired,
    setMessage: PropTypes.func.isRequired,
}

MessageBox.defaultProps = {

}
export default MessageBox
