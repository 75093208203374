import styled from 'styled-components';

export const BoxWraper = styled.div`
    max-width: 500px;    
    position: relative;  
    display: inline;
    border-radius: 8px;    
    border: none;
    overflow: hidden;
    flex: 1;
`;

export const Input = styled.input`
    padding: 4px 32px 4px 8px;
    width:100%;
    border:none;
    border-radius: 8px;
    outline: none;   
    font-size: var(--fontNormal);
`;

export const BtnClear = styled.div`
    position: absolute;
    top:0;
    right: 8px;
    bottom:0;
    border: none;
    background: transparent;
    font-size: 1rem;
    color: #a3a3a3;
    cursor: pointer;
    :hover{
        color:#838383;
        transform:scale(1.2);            
    }
`;