import { useEffect, useState } from 'react';
import loginApi from 'api/loginApi';
import { MESSAGE, URL_HOME } from 'constants';
import { LocalStorageSetToken } from 'utils';

const initUseInput = {
    userID: '',
    password: ''
}
export const useLogin = () => {
    const [useInput, setUseInput] = useState(initUseInput);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });

    useEffect(() => {
        (async function login() {
            if (useInput.userID === '' || useInput.password === '') return;
            try {
                setIsLoading(true);
                /**Call Api */
                const loginUser = {
                    userID: useInput.userID,
                    password: useInput.password
                }

                const dtResponse = await loginApi.login(loginUser);

                const xToken = dtResponse?.data['X-Token'] ?? "";
                const xUserID = dtResponse?.data['X-UserID'] ?? "";
                const staffID = dtResponse?.data['StaffID'] ?? "";
                const staffName = dtResponse?.data['StaffName'] ?? "";

                if (xToken && xUserID) {
                    LocalStorageSetToken(xUserID, xToken, staffID, staffName);
                    window.location.replace(URL_HOME);
                    setIsLoading(false);
                } else {
                    throw new Error('エラー');
                }
            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: ['ログインに失敗しました。']
                }
                setMessage(errMsg);
            }
        })();

    }, [useInput])

    return { setUseInput, isLoading, message, setMessage };
}